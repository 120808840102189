import React, { Component } from "react";
import Layout from "../components/layout"
import { graphql } from "gatsby";
import SEO from "../components/seo"

class SupportPage extends Component {
  componentDidMount() {
    var links = document.querySelectorAll( '.about-page a' );
     for (var i = 0, length = links.length; i < length; i++) {
       if (links[i].hostname != window.location.hostname) {
           links[i].target = '_blank';
       }
     }
  }
  render() {
    const data = this.props.data.allContentfulSupportPage.edges[0];
    return (
      <Layout>
        <SEO title="futurefeed | support" />
        <div className="about-page">
          <h2>{data.node.intro}</h2>
          <div
            className="bio"
            dangerouslySetInnerHTML={{ __html: data.node.mainText.childMarkdownRemark.html }}
        />
        </div>
      </Layout>
    )
  }
}

export default SupportPage

export const supportQuery = graphql`
  query SupportQuery {
    allContentfulSupportPage {
      edges {
        node {
          id
          intro
          mainText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
